import React from 'react';
import { useIntl } from "gatsby-plugin-intl"
import { Layout, SEO } from 'components/common';
import { Intro, Projects, Skills, GithubRepos, Companies } from 'components/landing';
import sakura from 'assets/company-logos/sakuratokyo-logo.png'
import imaid from 'assets/company-logos/imaid-logo.png'
import cpplogo from 'assets/company-logos/cpp.png'

export default () => {
  const intl = useIntl()

  const contributions= [
    {owner: "ethereum", name: "ethereum-org-website"},
    {owner: "tensorflow", name: "docs"},
  ]

  const companies = [
    {img:cpplogo, size: "100"},
    {img:sakura, size: "100"},
    {img:imaid, size: "100"},
  ]

  const projects = [
    {
      name: intl.formatMessage({ id: "project-1-name" }),
      description: intl.formatMessage({ id: "project-1-description" }),
      to: "/projects/sakura-quiz",
    },
    {
      name: intl.formatMessage({ id: "project-2-name" }),
      description: intl.formatMessage({ id: "project-2-description" }),
      to: "/projects/sakura-quiz",
    },
  ]

  return (
  <Layout>
    <SEO />
    <Intro />
    <Skills />
    <Projects title={intl.formatMessage({ id: "my-recent-works" })} data={projects} />
    <Companies logos={companies}/>
    {/* <GithubRepos title={"Contribution"} data={contributions} theme={"dark"}/> */}
    {/* <Contact /> */}
  </Layout>);
};
